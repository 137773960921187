import scrollToElement from 'scroll-to-element';

export const onClientEntry = () => {};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    scrollToElement(location.hash, { duration: 400 });
  } else {
    window.scrollTo({ top: 0 });
  }

  return false;
};

// Displaying a message when a service worker updates ~DH
// https://www.gatsbyjs.org/docs/add-offline-support-with-a-service-worker/
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated.
     Reload to display the latest version?`,
  );
  if (answer === true) {
    window.location.reload();
  }
};

// Gatsby will register your custom service worker. ~DH
export const registerServiceWorker = () => true;
